<template>
  <div class="container-fluid">
    <div class="login-topbar px-4 py-2">
      <b-row class="new-row">
        <b-col cols="6">
          <img src="/img/bludots.svg " alt />
        </b-col>
        <b-col cols="6" class="text-right">
          <div class="item">
            <a href="#" @click.prevent="cancelStep">
                <span class="icon">
                    <v-icon :icon="['fac', 'close']" />
                </span>
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="row justify-content-around align-items-center zoom">
      <div class="col-md-12 p-0">
        <div class="auth-right-header">
          <p>{{ $t("auth.general.isHaveAccount") }}</p>
          <router-link to="/auth/login" class="btn btn-outline-primary">
            {{ $t("auth.general.signIn") }}
          </router-link>
          <a href="#" class="menu-btn d-none">
            <v-icon icon="bars" />
          </a>
        </div>
      </div>
    </div>
    <div 
    class=" register-form row justify-content-around align-items-center form-area-width-register zoom"
    style="margin-top:100px;"
    
    >
      <div class="  col-lg-8 col-md-10 col-sm-12">
        <h4 class="auth-title-semi mt-4 text-left">
          {{ $t("auth.register.desc") }}
        </h4>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form action="#" @submit.prevent="handleSubmit(formSubmit)">
            <div class="form-group mb-4 d-flex justify-content-between">
              <div class="w-50 mr-3">
                <validation-provider
                  name="Name"
                  :rules="{ required: true, min: 3 }"
                  v-slot="validationContext"
                >
                  <label for="#joinName">{{
                    $t("auth.general.nameLabel")
                  }}</label>
                  <div
                    class="input-group input-icon bg"
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                  >
                    <b-form-input
                      :placeholder="$t('auth.general.name')"
                      type="text"
                      id="joinName"
                      v-model.trim="form.name"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <v-icon :icon="['fac', 'user']" />
                      </span>
                    </div>
                  </div>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>
              <div class="w-50">
                <validation-provider
                  name="surname"
                  :rules="{ required: true, min: 3 }"
                  v-slot="validationContext"
                >
                  <label for="#joinSurname">
                    {{ $t("auth.general.surname") }}
                  </label>
                  <div
                    class="input-group input-icon bg"
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                  >
                    <b-form-input
                      :placeholder="$t('auth.general.surname')"
                      type="text"
                      id="joinSurname"
                      v-model.trim="form.surname"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <v-icon :icon="['fac', 'user']" />
                      </span>
                    </div>
                  </div>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>
            </div>
            <div class="form-group">
              <validation-provider
                name="email"
                :rules="{ required: true, min: 3, email: true }"
                v-slot="validationContext"
              >
                <label for="#joinEmail">{{
                  $t("auth.general.emailLabel")
                }}</label>
                <div
                  class="input-group input-icon bg"
                  :class="{
                    'is-invalid':
                      !getValidationState(validationContext) &&
                      validationContext.errors[0],
                  }"
                >
                  <b-form-input
                    :placeholder="$t('auth.general.email')"
                    type="email"
                    id="joinEmail"
                    name="email"
                    v-model.trim="form.email"
                  ></b-form-input>
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <v-icon :icon="['fas', 'envelope']" />
                    </span>
                  </div>
                </div>
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </validation-provider>
            </div>

            <div class="form-group">
              <validation-provider
                name="password"
                :rules="{ required: true, min: 8 }"
                v-slot="validationContext"
              >
                <label for="#joinPw">{{
                  $t("auth.general.passwordLabel")
                }}</label>
                <div
                  class="input-group input-icon bg"
                  :class="{
                    'is-invalid':
                      !getValidationState(validationContext) &&
                      validationContext.errors[0],
                  }"
                >
                  <b-form-input
                    :placeholder="$t('auth.general.password')"
                    type="password"
                    id="joinPw"
                    name="password"
                    v-model.trim="form.password"
                  ></b-form-input>
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <v-icon :icon="['fas', 'lock']" />
                    </span>
                  </div>
                </div>
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </validation-provider>
            </div>

            <div class="form-group mb-4">
              <validation-provider
                :name="$t('auth.general.password')"
                :rules="{ required: true, min: 8, confirmed: 'password' }"
                v-slot="validationContext"
              >
                <label for="#joinPw_r">{{
                  $t("auth.general.repeat-password")
                }}</label>
                <div
                  :class="{
                    'is-invalid':
                      !getValidationState(validationContext) &&
                      validationContext.errors[0],
                  }"
                  class="input-group input-icon bg"
                >
                  <b-form-input
                    :placeholder="$t('auth.general.repeat-password')"
                    type="password"
                    id="joinPw_r"
                    v-model.trim="form.pw_r"
                  ></b-form-input>
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <v-icon :icon="['fas', 'lock']" />
                    </span>
                  </div>
                </div>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </validation-provider>
            </div>

            <div class="form-group mt-4 mb-0">
              <button class="btn btn-primary btn-block">
                {{ $t("auth.general.join") }}
              </button>
            </div>
          </form>
        </validation-observer>
        <div class="border-title">
          <p class="title">{{ $t("auth.general.or") }}</p>
        </div>
        <div class="form-group my-4">
          <a
            :href="`${API_DOMAIN}/auth/linkedin`"
            class="btn btn-linkedin btn-block"
          >
            {{ $t("auth.general.joinLinkedin") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import step1 from "../../../graphql/auth/register/step1.gql";
import sha256 from "sha256";
import { mapMutations } from "vuex";
import { decode } from 'js-base64';
export default {
  name: "Register-page",
  data() {
    return {
      loading: false,
      form: {
        name: null,
        surname: null,
        email: null,
        password: null,
        password_r: null,
        linkedin: false,
      },
    };
  },
  methods: {
    getLinkedQ() {
      if (this.$route.query.q) {
        let parsedLinkedin = JSON.parse(decode(this.$route.query.q));
        this.form.name = parsedLinkedin.name;
        this.form.surname = parsedLinkedin.surname;
        this.form.email = parsedLinkedin.email;
        this.form.password = parsedLinkedin.password;
        this.form.password_r = parsedLinkedin.password;
        this.form.linkedin = true;
        this.formSubmit();
      }
    },
    ...mapMutations({
      setEmail: "general/setEmail",
      setCompleteStep: "general/setCompleteStep",
    }),
    async formSubmit() {
      try {
        this.loading = true;
        let variable = {
          email: this.form.email,
          password: sha256(this.form.password),
          name: this.form.name,
          surname: this.form.surname,
          linkedin: this.form.linkedin,
        };
        let data = await this.$apollo.mutate(step1, variable);
        let response = data("**.registerStep1");
        this.setEmail(response.email);
        this.setCompleteStep(-1);
        /* this.$router.push("/register-steps/step-1", {
          query: {
            q: this.$route.query.q,
          },
        }); */

        if (this.form.linkedin) {
          this.$router.push("/register-steps/step-1", {
            query: {
              q: this.$route.query.q,
            },
          });
        } else {
          this.$router.push({
            path: "/auth/confirm-mail",
          });
        }
      } catch (e) {
        console.log(e);
        let msg = this.$options.filters.gqlep(e.message);

        if (/EMAIL/i.test(e.message)) {
          return this.$refs.observer.setErrors({ email: [msg] });
        }

        console.log(msg);
      } finally {
        this.loading = false;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid;
      }
      return null;
    },
  },
  created() {
    this.getLinkedQ();
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"login-topbar px-4 py-2"},[_c('b-row',{staticClass:"new-row"},[_c('b-col',{attrs:{"cols":"6"}},[_c('img',{attrs:{"src":"/img/bludots.svg ","alt":""}})]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('div',{staticClass:"item"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelStep.apply(null, arguments)}}},[_c('span',{staticClass:"icon"},[_c('v-icon',{attrs:{"icon":['fac', 'close']}})],1)])])])],1)],1),_c('div',{staticClass:"row justify-content-around align-items-center zoom"},[_c('div',{staticClass:"col-md-12 p-0"},[_c('div',{staticClass:"auth-right-header"},[_c('p',[_vm._v(_vm._s(_vm.$t("auth.general.isHaveAccount")))]),_c('router-link',{staticClass:"btn btn-outline-primary",attrs:{"to":"/auth/login"}},[_vm._v(" "+_vm._s(_vm.$t("auth.general.signIn"))+" ")]),_c('a',{staticClass:"menu-btn d-none",attrs:{"href":"#"}},[_c('v-icon',{attrs:{"icon":"bars"}})],1)],1)])]),_c('div',{staticClass:" register-form row justify-content-around align-items-center form-area-width-register zoom",staticStyle:{"margin-top":"100px"}},[_c('div',{staticClass:"  col-lg-8 col-md-10 col-sm-12"},[_c('h4',{staticClass:"auth-title-semi mt-4 text-left"},[_vm._v(" "+_vm._s(_vm.$t("auth.register.desc"))+" ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.formSubmit)}}},[_c('div',{staticClass:"form-group mb-4 d-flex justify-content-between"},[_c('div',{staticClass:"w-50 mr-3"},[_c('validation-provider',{attrs:{"name":"Name","rules":{ required: true, min: 3 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"#joinName"}},[_vm._v(_vm._s(_vm.$t("auth.general.nameLabel")))]),_c('div',{staticClass:"input-group input-icon bg",class:{
                    'is-invalid':
                      !_vm.getValidationState(validationContext) &&
                      validationContext.errors[0],
                  }},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('auth.general.name'),"type":"text","id":"joinName","aria-describedby":"input-1-live-feedback"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('v-icon',{attrs:{"icon":['fac', 'user']}})],1)])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-50"},[_c('validation-provider',{attrs:{"name":"surname","rules":{ required: true, min: 3 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"#joinSurname"}},[_vm._v(" "+_vm._s(_vm.$t("auth.general.surname"))+" ")]),_c('div',{staticClass:"input-group input-icon bg",class:{
                    'is-invalid':
                      !_vm.getValidationState(validationContext) &&
                      validationContext.errors[0],
                  }},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('auth.general.surname'),"type":"text","id":"joinSurname","aria-describedby":"input-1-live-feedback"},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.surname"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('v-icon',{attrs:{"icon":['fac', 'user']}})],1)])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"email","rules":{ required: true, min: 3, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"#joinEmail"}},[_vm._v(_vm._s(_vm.$t("auth.general.emailLabel")))]),_c('div',{staticClass:"input-group input-icon bg",class:{
                  'is-invalid':
                    !_vm.getValidationState(validationContext) &&
                    validationContext.errors[0],
                }},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('auth.general.email'),"type":"email","id":"joinEmail","name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('v-icon',{attrs:{"icon":['fas', 'envelope']}})],1)])],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"password","rules":{ required: true, min: 8 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"#joinPw"}},[_vm._v(_vm._s(_vm.$t("auth.general.passwordLabel")))]),_c('div',{staticClass:"input-group input-icon bg",class:{
                  'is-invalid':
                    !_vm.getValidationState(validationContext) &&
                    validationContext.errors[0],
                }},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('auth.general.password'),"type":"password","id":"joinPw","name":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.password"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('v-icon',{attrs:{"icon":['fas', 'lock']}})],1)])],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group mb-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.general.password'),"rules":{ required: true, min: 8, confirmed: 'password' }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"#joinPw_r"}},[_vm._v(_vm._s(_vm.$t("auth.general.repeat-password")))]),_c('div',{staticClass:"input-group input-icon bg",class:{
                  'is-invalid':
                    !_vm.getValidationState(validationContext) &&
                    validationContext.errors[0],
                }},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('auth.general.repeat-password'),"type":"password","id":"joinPw_r"},model:{value:(_vm.form.pw_r),callback:function ($$v) {_vm.$set(_vm.form, "pw_r", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.pw_r"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('v-icon',{attrs:{"icon":['fas', 'lock']}})],1)])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group mt-4 mb-0"},[_c('button',{staticClass:"btn btn-primary btn-block"},[_vm._v(" "+_vm._s(_vm.$t("auth.general.join"))+" ")])])])]}}])}),_c('div',{staticClass:"border-title"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("auth.general.or")))])]),_c('div',{staticClass:"form-group my-4"},[_c('a',{staticClass:"btn btn-linkedin btn-block",attrs:{"href":(_vm.API_DOMAIN + "/auth/linkedin")}},[_vm._v(" "+_vm._s(_vm.$t("auth.general.joinLinkedin"))+" ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }